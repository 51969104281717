import {
  IonButton,
  IonContent,
  IonIcon,
  IonInput,
  IonItem,
  IonLabel,
  IonLoading,
  IonPage,
  IonText,
} from '@ionic/react';
import { FormEvent, useRef } from 'react';
import { useTranslation } from 'react-i18next';

import { AuthMode } from '@carers/views/Auth/AuthMode';
import AuthHeader from '@carers/views/Auth/components/AuthHeader';
import useLoginMode from '@carers/views/Auth/hooks/useLoginMode';
import { useRequestSigninCode } from '@carers/views/Auth/hooks/useRequestSigninCode';
import usePhoneMask from '@carers/views/Auth/Login/hooks/usePhoneMask';
import LoginModeInput from '@carers/views/Auth/Login/LoginModeCode';
import { Logo, ShadeEnum } from '@shared/components/Logo/Logo';
import { CORPORATE_WEBSITE_CARERS_SIGNIN_URL } from '@shared/constants/urlConstants';

import LogoHandWithLock from '../assets/auth-image.svg';
import stylesAuth from '../Auth.module.css';

import styles from './Login.module.css';

const removeAllSpaces = (phone: string) => phone.replaceAll(' ', '');

const Login = () => {
  const { t } = useTranslation('app', { keyPrefix: 'carers.auth.login' });
  const inputRef = useRef<HTMLIonInputElement | null>(null);
  const phoneMask = usePhoneMask();
  const { loginMode, setLoginMode } = useLoginMode();
  const loginModeInput = LoginModeInput[loginMode];
  const {
    isPending: isLoading,
    mutate: requestSigninCodeMutation,
    isError,
    error,
    reset: resetSigninCodeMutation,
  } = useRequestSigninCode();

  const handleSubmit = (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    const loginValue = inputRef.current?.value ?? '';
    requestSigninCodeMutation({ mode: loginMode, value: removeAllSpaces(loginValue.toString()) });
  };

  const toggleLoginMode = () => {
    resetSigninCodeMutation();
    setLoginMode(loginModeInput.otherType, undefined, 'replace');

    setTimeout(async () => {
      await inputRef.current?.setFocus();
    }, 100);
  };

  const redirectToCorporateWebsite = () => {
    window.location.href = CORPORATE_WEBSITE_CARERS_SIGNIN_URL;
  };

  return (
    <IonPage>
      <IonLoading isOpen={isLoading} message={t('messages.info.sendingCode')} />
      <AuthHeader />
      <IonContent className="ion-padding">
        <div className={`${stylesAuth.pageContent} stack stack-large`}>
          <Logo className="ion-hide-xl-down" aria-hidden shade={ShadeEnum.dark} />
          <img src={LogoHandWithLock} alt="" aria-hidden />
          <h3>{t('welcome')}</h3>
          <IonLabel>{t(loginModeInput.info)}</IonLabel>
          <form onSubmit={handleSubmit} className="stack">
            <div>
              <IonItem
                lines="none"
                className={`${isError ? 'ion-invalid ion-touched' : ''} ${styles.inputItem}`}
              >
                <IonIcon
                  icon={loginModeInput.icon}
                  slot="start"
                  aria-hidden
                  color={`${isError ? 'danger' : 'primary'}`}
                />
                <IonInput
                  ref={async (phoneInput) => {
                    inputRef.current = phoneInput;

                    if (phoneInput && loginMode === AuthMode.SMS) {
                      phoneMask(await phoneInput.getInputElement());
                    }
                  }}
                  key={loginModeInput.name}
                  name={loginModeInput.name}
                  type={loginModeInput.inputType}
                  label={t(loginModeInput.label)}
                  autocomplete={loginModeInput.inputType}
                  inputmode={loginModeInput.inputType}
                  labelPlacement="floating"
                  required
                />
              </IonItem>
              {isError && <IonText className="error-text">{error.errorMessage}</IonText>}
            </div>
            <IonButton
              fill="clear"
              type="button"
              className={`${styles.footerButton} button-link`}
              onClick={() => toggleLoginMode()}
            >
              {t(loginModeInput.switchText)}
            </IonButton>

            <IonButton
              fill="clear"
              type="button"
              className={`${styles.footerButton} button-link`}
              onClick={() => redirectToCorporateWebsite()}
            >
              {t('actions.redirectToCorporateWebsite')}
            </IonButton>
            <IonButton type="submit" className={styles.footerButton}>
              {t('actions.receiveCode')}
            </IonButton>
          </form>
        </div>
      </IonContent>
    </IonPage>
  );
};

export default Login;
