/* eslint-disable no-console */
import { App } from '@capacitor/app';
import { PluginListenerHandle } from '@capacitor/core';
import { isPlatform } from '@ionic/react';
import { useEffectOnce } from 'usehooks-ts';

import useAuthState from '@carers/hooks/useAuthState';
import useRedirectPathAfterSignIn from '@carers/hooks/useRedirectPathAfterSignIn';

const AppUrlOpenListener = () => {
  const { isAuth } = useAuthState();
  const { setRedirectPathAfterSignIn } = useRedirectPathAfterSignIn();

  const initAppUrlOpenListener = async (signal: AbortSignal) => {
    let listener: PluginListenerHandle;

    const removeListener = () => {
      listener.remove().catch(() => {});
    };

    listener = await App.addListener('appUrlOpen', (event) => {
      const url = new URL(event.url);

      if (url.pathname) {
        if (!isAuth) {
          setRedirectPathAfterSignIn(url.pathname);
        }

        window.location.replace(url.pathname);
      }
    });

    if (signal.aborted) {
      removeListener();
    } else {
      signal.addEventListener('abort', removeListener, { once: true });
    }
  };

  useEffectOnce(() => {
    const controller = new AbortController();

    if (isPlatform('capacitor')) {
      initAppUrlOpenListener(controller.signal).catch(() => {});
    }

    return () => controller.abort();
  });

  return null;
};

export default AppUrlOpenListener;
