const RETOOL_APP_PUBLIC_URL = 'https://retool.mediflash.fr/p';

const REQUEST_CREATION_FORM_WIDGET_URL = `${RETOOL_APP_PUBLIC_URL}/facilities-create-request`;

const REQUESTS_LIST_WIDGET_URL = `${RETOOL_APP_PUBLIC_URL}/facilities-requests`;

const REQUEST_DETAIL_WIDGET_URL = `${RETOOL_APP_PUBLIC_URL}/facilities-request-detail`;

const TASKS_LIST_WIDGET_URL = `${RETOOL_APP_PUBLIC_URL}/facilities-tasks`;

const TASKS_DETAIL_WIDGET_URL = `${RETOOL_APP_PUBLIC_URL}/facilities-selected-task`;

const HOME_WIDGETS_URL = `${RETOOL_APP_PUBLIC_URL}/facilities-home-widgets`;

const FEEDBACK_WIDGET_URL = `${RETOOL_APP_PUBLIC_URL}/facilities-send-feedback`;

const CARERS_WIDGET_URL = `${RETOOL_APP_PUBLIC_URL}/facilities-carers`;

export {
  CARERS_WIDGET_URL,
  FEEDBACK_WIDGET_URL,
  HOME_WIDGETS_URL,
  REQUEST_CREATION_FORM_WIDGET_URL,
  REQUEST_DETAIL_WIDGET_URL,
  REQUESTS_LIST_WIDGET_URL,
  TASKS_DETAIL_WIDGET_URL,
  TASKS_LIST_WIDGET_URL,
};
