import { IonContent, IonHeader, IonPage, IonTitle, IonToolbar } from '@ionic/react';
import { useRef } from 'react';
import { useTranslation } from 'react-i18next';

import RoundWaveContentHeader from '@carers/components/RoundWaveContentHeader/RoundWaveContentHeader';
import CalendarMissionsByMonth from '@carers/views/Activity/components/MissionsByMonth/CalendarMissionsByMonth';
import UserTasksList from '@carers/views/Activity/components/UserTasks/UserTasksList';
import LogoMediflashLinear from '@shared/assets/images/logo-mediflash-linear.svg';
import Refresher from '@shared/components/Refresher/Refresher';

type UserTasksListRef = {
  refetch: () => Promise<void>;
};

const Activity = () => {
  const { t } = useTranslation('app');

  const userTasksListRef = useRef<UserTasksListRef | null>(null);
  const calendarMissionsByMonthRef = useRef<UserTasksListRef | null>(null);

  const refetch = async () => {
    await Promise.all([
      userTasksListRef.current?.refetch(),
      calendarMissionsByMonthRef.current?.refetch(),
    ]);
  };

  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <IonTitle className="ion-hide-xl-up ion-text-center">
            <img src={LogoMediflashLinear} width="150" height="26" alt={t('shared.appName')} />
          </IonTitle>
          <IonTitle className="ion-hide-xl-down">{t('carers.activity.title')}</IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent className="withBlob ion-padding">
        <Refresher refetch={refetch} />
        <RoundWaveContentHeader />
        <div className="stack">
          <UserTasksList ref={userTasksListRef} />
          <CalendarMissionsByMonth ref={calendarMissionsByMonthRef} />
        </div>
      </IonContent>
    </IonPage>
  );
};

export default Activity;
